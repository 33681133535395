<template>
  <div class="refundhistory">
    <van-nav-bar
      title="退款记录"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="main" v-if="!nodata">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="getList"
        :immediate-check="false"
      >
        <van-cell v-for="(item, index) in list" :key="index">
          <template #title>
            <div class="title">
              <div class="row">
                <div class="row_c1" @click="copay(item.ordersn)">订单编号：{{item.ordersn}} <van-tag plain>复制</van-tag></div>
              </div>
              <div class="row">
                <div class="row_c1">退款编号：{{item.applyno}}</div>
              </div>
              <div class="row" v-if="item.content && item.content.length > 2">
                <div class="row_c1">{{item.content}}</div>
              </div>
              <div class="row" v-if="item.reject_reason && item.reject_reason.length > 2">
                <div class="row_c1">{{item.reject_reason}}</div>
              </div>
              <div class="row">
                <div class="row_c1 time">申请时间：{{item.create_time}}</div>
                <div>申请金额：&yen;{{item.apply_price}}</div>
              </div>
              <div class="row">
                <div class="row_c1 time" >
                  {{item.pay_time && item.pay_time.length > 2 ? "打款时间：" + item.pay_time : ""}}
                  {{ item.reject_time && item.reject_time.length > 2 ? "驳回时间：" + item.reject_time : ""}}
                </div>
                <div class="success">{{item.status_desc}}</div>
              </div>
            </div>
          </template>
        </van-cell>
      </van-list>
    </div>
    <div class="main" v-if="nodata">
      <van-empty description="暂无记录" />
    </div>
  </div>
</template>
<script>
export default {
  name: "refundHistory",
  data() {
    return {
      orderid: 0,
      shareid: 0,
      merchid: 0,
      size: 20,
      offset: 0,
      list: [],
      loading: false,
      finished: false,
      nodata: false
    }
  },
  mounted() {
    this.orderid = this.$route.query.id ? this.$route.query.id : 0;
    if (this.$route.query.shareid) {
      this.shareid = this.$route.query.shareid
    }else if (localStorage.getItem("DK_MEMBERID")) {
      this.shareid = localStorage.getItem("DK_MEMBERID");
    }
    this.merchid = this.$route.query.merchid ? this.$route.query.merchid : 0;
    this.$parent.getmerchname(this.merchid, '退款记录');
    
    this.init();
  },
  methods: {
    init() {
      console.log("init");
      this.getList();
    },
    onClickLeft() {
      this.$router.back();
    },
    getList() {
      console.log("getList");
      let that = null
      that = this;
      that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("DK_UID");
      that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      that.$axios
        .post(
          that.$store.state.domain + "web/order/jilu_mini_buchang",
          that.$qs.stringify({
            oid: that.orderid
          })
        )
        .then(res => {
          console.log(res);
          if (res.data.code == 100000) {
            that.list = res.data.data;
            that.loading = false;
            that.finished = true;
            that.nodata = false;
          }else {
            that.list = [];
            that.loading = false;
            that.finished = true;
            that.nodata = true;
            that.$toast(res.data.msg ? res.data.msg : "暂无记录")
          }
        })
        .catch(err => {
          console.log(err);
        })
    },
    // 复制
    copay(text) {
      console.log(text);
      let _that = null;
      _that = this;
      _that.$copyText(text).then(
        function(e) {
          _that.$toast("复制成功");
          console.log(e);
        },
        function(e) {
          console.log(e);
        }
      );
    }
  },
}
</script>
<style lang="less">
  .refundhistory {
    min-height: 100%;
    width: 100%;
    background-color: #fff;
    .van-nav-bar .van-icon {
      color: #333;
    }
    .main {
      .van-cell__title {
        flex: 1;
        text-align: left;
        overflow: hidden;
      }
      .title {
        overflow: hidden;
        .row {
          display: flex;
          overflow: hidden;
          .row_c1 {
            flex: 1;
            overflow: hidden;
          }
          .time {
            font-size: 12px;
            color: #999;
          }
          .success {
            color: #07C160;
          }
        }
      }
    }
  }
</style>